import { AdSlot } from '#components'

declare global {
  interface Window { acomtag: any, OB_ContextKeyValue: any, OBR: any }
}

export default function useAd() {
  const config = useState<AdConfig | null>('adConfig', () => null)

  const getSlot = (slot: PossibleAdSlots) => {
    const currentSlot = config.value?.page.slots.find(s => s.id === slot)

    if (currentSlot) {
      return currentSlot
    }

    return config.value?.loops.content?.slots.find(s => s.id === slot)
  }

  const getProvider = <T extends AdTypes>(type: T): AdSetup<T> | undefined => {
    return config.value?.setup[type]
  }

  const addSlotsToContent = (content: Post) => {
    if (!config.value) {
      return content
    }
    const slots = config.value.loops.content?.slots || []

    // if blocks have already been added
    if (content.blocks.some(block => 'component' in block && block.component === AdSlot)) {
      return content
    }

    const post = { ...content }

    let row = 0

    post.blocks = post.blocks.flatMap((block, index) => {
      if ('component' in block) {
        return block
      }

      if (index === 0) {
        const foundSlots = []
        foundSlots.push(config.value?.page.slots.find(s => s.id === 'contentad_1'))
        foundSlots.push(config.value?.page.slots.find(s => s.id === 'bannerparagraph_0'))

        // add the first ad slot
        return [
          block,
          ...foundSlots
            .filter(slot => slot !== undefined)
            .map(slot => ({
              t: 'VueBlock',
              a: {
                component: markRaw(AdSlot),
                props: {
                  adSlot: slot.id,
                },
              },
            })),
        ]
      }

      if (block.ot !== 'core/paragraph') {
        return block
      }

      row += 1

      const foundSlots = slots.filter(s => s.row === (row - 1))

      if (foundSlots.length > 0) {
        return [
          block,
          ...foundSlots.map(slot => ({
            t: 'VueBlock',
            a: {
              component: markRaw(AdSlot),
              props: {
                adSlot: slot.id,
              },
            },
          })),
        ]
      }

      return block
    })

    return post
  }

  const handleMocaInit = (config: AdConfig) => {
    if (!config.setup.moca) {
      return
    }

    const url = `${config.setup.moca.url}/moca.php?id=${config.setup.moca.id}`
    const { onLoaded } = useScript({
      src: url,
      crossorigin: undefined,
      type: 'text/javascript',
    }, {
      trigger: consentGiven,
      tagPriority: 'low',
    })

    const auth = useAuth()
    onLoaded(() => {
      const plusSubscriber = auth.getUser()?.piano?.abo?.hasPlusAccess || auth.getUser()?.piano?.abo?.hasPlusPremiumAccess
      if (plusSubscriber === true) {
        window.acomtag.data.push(['plussubscriber', ['true']])
      }
      else {
        window.acomtag.data.push(['plussubscriber', ['false']])
      }

      const gemeinde = useCookie('CurrentCity')
      if (gemeinde.value) {
        window.acomtag.data.push(['gemeinde', [gemeinde]])
      }
    })
  }

  const handleOutbrainInit = (config: AdConfig) => {
    if (!config.setup.outbrain) {
      return
    }

    const url = `https://widgets.outbrain.com/outbrain.js?i=${config.setup.outbrain.token_id}`
    const { onLoaded } = useScript({
      src: url,
      crossorigin: undefined,
      type: 'text/javascript',
    }, {
      trigger: consentGiven,
      tagPriority: 'low',
    })

    const auth = useAuth()
    onLoaded(() => {
      if (config.setup.outbrain?.premium) {
        window.OB_ContextKeyValue = 'premium'
      }

      window.OBR.extern.researchWidget()
      const plusSubscriber = auth.getUser()?.piano?.abo?.hasPlusAccess || auth.getUser()?.piano?.abo?.hasPlusPremiumAccess
    })
  }

  const handleApaInit = (config: AdConfig) => {
    if (!config.setup.apa) {
      return
    }

    console.log('apa init - not yet implemented')
  }

  const setConfig = (newConfig: AdConfig) => {
    config.value = newConfig

    handleMocaInit(config.value)
    handleOutbrainInit(config.value)
    handleApaInit(config.value)
  }

  return {
    getSlot,
    getProvider,
    setConfig,
    addSlotsToContent,
  }
}
