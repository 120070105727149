<template>
  <div
    data-allow-mismatch="children"
    :style="height"
    :class="{
      'md:hidden': adSlot?.mobile === true,
      'hidden': adSlot?.mobile === false || adSlot?.mobile === undefined,
      'md:block': adSlot?.mobile === undefined,
    }"
  >
    <component
      :is="adType"
      :ad-slot="adSlot"
      :class="adSlot?.css_class"
    />
  </div>
</template>

<script setup lang="ts">
import { LazyAdProviderMoca, LazyAdProviderOutbrain } from '#components'

const props = defineProps<{ adSlot: PossibleAdSlots }>()

const { getSlot } = useAd()

const adSlot = computed(() => {
  return getSlot(props.adSlot)
})

const height = computed(() => {
  return {
    'min-height': adSlot.value?.props?.fixed_size?.enabled ? `${adSlot.value.props?.fixed_size?.['min-height']}px` : 'auto',
  }
})

const adType = computed(() => {
  const components = {
    generic: 'div',
    moca: LazyAdProviderMoca,
    outbrain: LazyAdProviderOutbrain,
    apa: 'div',
  }

  if (!adSlot.value?.type) {
    return 'div'
  }

  return components[adSlot.value?.type]
})
</script>
